.emphasis {
  padding: 0px 10px 0px 6px;
  position: relative;
  color: #fff;
}

.emphasis-wrapper {
    display: inline-block;
}

.emphasis:before {
  content: "";
  background: #be1e2d;
  display: inline-block;
  position: absolute;
  left: -2.5%;
  padding: 1.1rem 0rem 1.2rem 0rem;
  right: 0;
  top: -2%;
  z-index: -1;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  transition: all 0.1s;
  -webkit-transform: rotate(-1deg) skew(10deg);
  transform: rotate(-1deg) skew(12deg);
  width: 102%;
}

.emphasis:after {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 120%;
  top: -4%;
  z-index: -2;
  -webkit-transform: rotate(0.3deg);
  transform: rotate(0.3deg);
}
