#App {
  .navbar-container {
    box-shadow: 0em 0.15em 1em rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }

  .navbar {
    border-bottom: 1px solid #dddddd;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  }

  .ui.secondary.menu a.item.navbar-logo:hover {
    background: inherit;
    color: inherit;
  }

  /* navbar right-menu */
  .ui.secondary.menu .right.menu .item {
    padding: 0.78em 0.5em;
  }

  /* navbar left-menu */
  .ui.secondary.menu .item {
    padding: 0.9em 1.1em;
  }

  /* navbar account dropdown */
  .ui.secondary.menu .item.dropdown .item {
    font-size: 1rem !important;
  }

  /* navbar items */
  .ui.secondary.menu .item.navbar-link,
  .ui.secondary.menu .item.dropdown {
    border-radius: 0;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: transparent;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    background-color: inherit;
    font-size: 1.214285rem;
  }
  .ui.secondary.menu .item.navbar-link:hover,
  .ui.secondary.menu .item.dropdown:hover,
  .ui.secondary.menu .item.dropdown.active {
    border-radius: 0;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: #be1e2d;
    background-color: inherit;
  }
}
