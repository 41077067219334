#App {
  /* navbar right-menu */
  /* navbar left-menu */
  /* navbar account dropdown */
  /* navbar items */ }
  #App .navbar-container {
    box-shadow: 0em 0.15em 1em rgba(0, 0, 0, 0.1);
    margin-bottom: 30px; }
  #App .navbar {
    border-bottom: 1px solid #dddddd;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04); }
  #App .ui.secondary.menu a.item.navbar-logo:hover {
    background: inherit;
    color: inherit; }
  #App .ui.secondary.menu .right.menu .item {
    padding: 0.78em 0.5em; }
  #App .ui.secondary.menu .item {
    padding: 0.9em 1.1em; }
  #App .ui.secondary.menu .item.dropdown .item {
    font-size: 1rem !important; }
  #App .ui.secondary.menu .item.navbar-link,
  #App .ui.secondary.menu .item.dropdown {
    border-radius: 0;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: transparent;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    background-color: inherit;
    font-size: 1.214285rem; }
  #App .ui.secondary.menu .item.navbar-link:hover,
  #App .ui.secondary.menu .item.dropdown:hover,
  #App .ui.secondary.menu .item.dropdown.active {
    border-radius: 0;
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: #be1e2d;
    background-color: inherit; }

.bm-menu {
    padding: .8em 1em 0;
    font-size: 1.15em;
  }
.bm-menu-wrap {
    background-color: #222;
}

.bm-item-list .list .item .icon.white {
    color: rgba(255, 255, 255, 0.788);
}

.bm-menu .bm-item-list .list {
    margin: 1em 0;
}

.bm-menu .bm-item-list .list .item {
    margin-bottom: 15px;
}

/* set width for icons so text lines up correctly */
.bm-item-list .list .item .icon {
    width: 43px;
}

#App .offcanvas-logo img{
    height: 60px;
}
.emphasis {
  padding: 0px 10px 0px 6px;
  position: relative;
  color: #fff;
}

.emphasis-wrapper {
    display: inline-block;
}

.emphasis:before {
  content: "";
  background: #be1e2d;
  display: inline-block;
  position: absolute;
  left: -2.5%;
  padding: 1.1rem 0rem 1.2rem 0rem;
  right: 0;
  top: -2%;
  z-index: -1;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-transform: rotate(-1deg) skew(10deg);
  transform: rotate(-1deg) skew(12deg);
  width: 102%;
}

.emphasis:after {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 120%;
  top: -4%;
  z-index: -2;
  -webkit-transform: rotate(0.3deg);
  transform: rotate(0.3deg);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

#App {
  font-size: 1.1rem;
  padding-bottom: 2em;
  /* ******************************************************* */
  /* segment styling */
  /* ******************************************************* */
  /* standard primary hyperlink */
  /* ******************************************************* */
  /* primary icon color */
  /* ******************************************************* */
  /* ******************************************************* */
  /* form error list */
  /* ******************************************************* */
  /* ******************************************************* */
  /* standard primary button */
  /* ******************************************************* */
  /* ******************************************************* */
  /* outline colored button */
  /* ******************************************************* */ }
  #App .ui.loading.form:after,
  #App .ui.loader:after {
    border-color: #a51a28 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1); }
  #App .segment .label.primary {
    background-color: #be1e2d;
    color: #fff; }
  #App .segment .label.secondary {
    background-color: #333;
    color: #fff; }
  #App .segment .subheader {
    color: #7b7b7b; }
  #App .segment .bottomPadding {
    padding-bottom: 8px; }
  #App .segment .subheader-value {
    font-weight: bold; }
  #App .hyperlink {
    color: #be1e2d; }
  #App .hyperlink:hover {
    color: #a51a28; }
  #App .hyperlink.underfield {
    display: block;
    font-size: 1rem;
    padding-top: 0.285714rem; }
  #App .hyperlink.white {
    color: #fff; }
  #App .hyperlink.white:hover {
    color: #e0e0e0; }
  #App i.red.icon {
    color: #a51a28 !important; }
  #App .form-errors .content ul {
    margin-bottom: 0px;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;
    -webkit-margin-before: 0.5em;
            margin-block-start: 0.5em; }
  #App .ui.primary.button,
  #App .ui.primary.button:active {
    background-color: #be1e2d; }
  #App .ui.primary.button:hover {
    background-color: #a51a28; }
  #App .ui.basic.red.button {
    color: #be1e2d !important;
    box-shadow: 0 0 0 1px #be1e2d inset !important;
    font-weight: 700; }
  #App .ui.basic.red.button:hover {
    color: #fff !important;
    box-shadow: 0 0 0 1px #be1e2d inset !important;
    background-color: #be1e2d !important; }

/* ******************************************************* */
@media only screen and (max-device-width: 550px) {
  .desktop-nav-menu {
    display: none !important; } }

/* ******************************************************* */
/* ******************************************************* */
@media only screen and (min-device-width: 551px) {
  .offcanvas-nav-menu {
    display: none !important; } }

/* ******************************************************* */
/* ******************************************************* */
@media only screen and (max-width: 767px) {
  /* Override semantic padding for stackable grid columns */
  #App .tile-heading {
    padding: 0rem 1rem !important; } }

/* ******************************************************* */

