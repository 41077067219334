.bm-menu {
    padding: .8em 1em 0;
    font-size: 1.15em;
  }
.bm-menu-wrap {
    background-color: #222;
}

.bm-item-list .list .item .icon.white {
    color: rgba(255, 255, 255, 0.788);
}

.bm-menu .bm-item-list .list {
    margin: 1em 0;
}

.bm-menu .bm-item-list .list .item {
    margin-bottom: 15px;
}

/* set width for icons so text lines up correctly */
.bm-item-list .list .item .icon {
    width: 43px;
}

#App .offcanvas-logo img{
    height: 60px;
}