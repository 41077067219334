body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#App {
  font-size: 1.1rem;
  padding-bottom: 2em;

  .ui.loading.form:after,
  .ui.loader:after {
    border-color: #a51a28 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  }

  /* ******************************************************* */
  /* segment styling */
  .segment .label.primary {
    background-color: #be1e2d;
    color: #fff;
  }
  .segment .label.secondary {
    background-color: #333;
    color: #fff;
  }
  .segment .subheader {
    color: #7b7b7b;
  }
  .segment .bottomPadding {
    padding-bottom: 8px;
  }
  .segment .subheader-value {
    font-weight: bold;
  }

  /* ******************************************************* */
  /* standard primary hyperlink */
  .hyperlink {
    color: #be1e2d;
  }
  .hyperlink:hover {
    color: #a51a28;
  }
  .hyperlink.underfield {
    display: block;
    font-size: 1rem;
    padding-top: 0.285714rem;
  }

  .hyperlink.white {
    color: #fff;
  }
  .hyperlink.white:hover {
    color: rgb(224, 224, 224);
  }

  /* ******************************************************* */
  /* primary icon color */
  i.red.icon {
    color: #a51a28 !important;
  }
  /* ******************************************************* */

  /* ******************************************************* */
  /* form error list */
  .form-errors {
    .content {
      ul {
        margin-bottom: 0px;
        padding-inline-start: 20px;
        margin-block-start: 0.5em;
      }
    }
  }
  /* ******************************************************* */

  /* ******************************************************* */
  /* standard primary button */
  .ui.primary.button,
  .ui.primary.button:active {
    background-color: #be1e2d;
  }
  .ui.primary.button:hover {
    background-color: #a51a28;
  }
  /* ******************************************************* */

  /* ******************************************************* */
  /* outline colored button */
  .ui.basic.red.button {
    color: #be1e2d !important;
    box-shadow: 0 0 0 1px #be1e2d inset !important;
    font-weight: 700;
  }
  .ui.basic.red.button:hover {
    color: #fff !important;
    box-shadow: 0 0 0 1px #be1e2d inset !important;
    background-color: #be1e2d !important;
  }
  /* ******************************************************* */

}

/* ******************************************************* */
@media only screen and (max-device-width: 550px) {
  .desktop-nav-menu {
    display: none !important;
  }
}
/* ******************************************************* */

/* ******************************************************* */
@media only screen and (min-device-width: 551px) {
  .offcanvas-nav-menu {
    display: none !important;
  }
}
/* ******************************************************* */

/* ******************************************************* */
@media only screen and (max-width: 767px) {
  /* Override semantic padding for stackable grid columns */
  #App .tile-heading {
    padding: 0rem 1rem !important;
  }
}
/* ******************************************************* */
